import moment from 'moment'

export default class Storage{

    constructor() {
        // check if localStorage exists
        localStorage
    }

    static set(key: string, elem: any){
        const value = JSON.stringify(elem);
        localStorage.setItem(key, value);
    }

    static get(key: string){
        const elem = localStorage.getItem(key);
        if(elem && elem !== 'undefined'){
            return JSON.parse(elem);
        }
        return null;
    }

    static getRaw(key: string){
        return localStorage.getItem(key);
    }

    static exists(key: string){
        return localStorage.getItem(key) !== null;
    }

    static clear(){
        localStorage.clear();
    }

    static remove(key: string) {
        localStorage.removeItem(key);
    }

    static clearUserInfo() {
        this.remove("user")

        for(const key in localStorage){
            const data = Storage.get(key)
            if(data?.is_user_info){
                Storage.remove(key)
            }
        }
    }
    
    static clearOldResponses() {
        for(const key in localStorage){
            const data = Storage.get(key)
            if(data?.last_update_expire){
                const last = moment(data.last_update_expire)
                if (moment().diff(last, 'days') > 7) {
                    Storage.remove(key)
                }
            }
        }
    }
}