// api service
export let apiProtocol: string;
export let apiHost: string;
export let apiTokenPath: string;
export let apiTokenRefresh: string;
export let formSchemaEndpoint: string;

if (process.env.NODE_ENV === "development") {
  apiProtocol = "https";
  apiHost = "dashboard-dev.callroom.app";
  apiTokenPath = "/api/auth/token";
  apiTokenRefresh = "/api/auth/token/refresh/";
  formSchemaEndpoint = "/api/app/settings/all_it_v1.9.0/";
}
else if (process.env.NODE_ENV === "production") {
  apiProtocol = "https";
  apiHost = "dashboard.callroom.app";
  apiTokenPath = "/api/auth/token";
  apiTokenRefresh = "/api/auth/token/refresh/";
  formSchemaEndpoint = "/api/app/settings/all_it_v1.9.0/";
}