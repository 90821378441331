
  import { defineComponent } from 'vue';
  import { IonIcon } from '@ionic/vue'
  import { cloudOfflineOutline } from 'ionicons/icons';
  import Storage from '@/models/Storage'
  import { Network } from '@capacitor/network';

  export default defineComponent({
    components: {
      IonIcon
    },
    setup() {
      const last_update = Storage.get("settings-lastupdate")
      let isOutdated = true
      if (last_update){
        const now = new Date()
        const l_date = new Date(last_update)
        const diff = now.getTime() - l_date.getTime()
        isOutdated = diff > (1000 * 60 * 60 * 24 * 7)
      }
      return {
        isOutdated: isOutdated,
        cloudOfflineOutline
      }
    },
    data(){
      return {
        isOffline: false
      }
    },
    async mounted(){
      const status = await Network.getStatus();
      if(status.connected === false){
        this.isOffline = true
      }

      Network.addListener('networkStatusChange', (status) => {
        const was_offline = this.isOffline
        if(status.connected == false){
          this.isOffline = true
        } else {
          this.isOffline = false
          if(was_offline){
            setTimeout(() => {
              window.location.reload()
            }, 300)
          }
        }
      });
    }
  });
