import { apiProtocol, apiHost } from '@/config/endpoints'
import { axiosInstance } from '@/config/access-token'
import Storage from '@/models/Storage'
import { isPlatform } from '@ionic/vue'

export const removeNotifications = () => {
    return axiosInstance({
        method: 'POST',
        url: `${apiProtocol}://${apiHost}/api/devices/delete/`,
        data: {
            'registration_id': Storage.get("registration_id")
        }
    });
}

export const addNotifications = () => {
    let type = 'web'
    if(isPlatform('mobileweb')){
        type = 'web'
    } else if(isPlatform('ios')){
        type = 'ios'
    } else if(isPlatform('android')){
        type = 'android'
    }

    return axiosInstance({
        method: 'POST',
        url: `${apiProtocol}://${apiHost}/api/devices/`,
        data: {
            'registration_id': Storage.get("registration_id"),
            'type': type
        }
    });
}

export const readNotification = (pk) => {
    return axiosInstance({
        method: 'PUT',
        url: `${apiProtocol}://${apiHost}/api/app/users/notifications/${pk}/read/`,
        data: {}
    });
}
