
  import { defineComponent } from 'vue';
  import { IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardContent, IonCardHeader, IonButton, isPlatform } from '@ionic/vue'
  import Storage from '@/models/Storage'

  export default defineComponent({
    components: { IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardContent, IonCardHeader, IonButton },
    setup(){
      return {
        messageMinVersion: Storage.get("app-messageMinVersion")
      }
    },
    methods:{
      redirectToStore(){
        const links = Storage.get("app-linksMinversion")
        if(links){
          if(isPlatform('android')){
            window.open(links['android'], "_blank")
          } else if(isPlatform('ios')){
            window.open(links['ios'], "_blank")
          }
        }
      }
    }
  });
