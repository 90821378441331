
import { defineComponent } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet, useIonRouter } from '@ionic/vue';
import { earthOutline, personOutline, settingsOutline, calendarOutline } from 'ionicons/icons';
import { isPlatform } from '@ionic/vue';

export default defineComponent({
  name: 'TabsPage',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {

    const router = useIonRouter()

    /* inserire qui l'hardware back button */

    return {
      earthOutline, 
      personOutline, settingsOutline, 
      calendarOutline,
    }
  },
  data () {
    return {
      tabPosition: isPlatform('desktop') ? 'top' : 'bottom'
    }
  },
});
