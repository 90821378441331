
  import { defineComponent } from 'vue';
  import { Autoplay, EffectFade, Keyboard, Pagination } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { IonContent } from '@ionic/vue'
  import { chevronForwardCircleOutline } from 'ionicons/icons';

  import 'swiper/css';
  import 'swiper/css/autoplay';
  import 'swiper/css/keyboard';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';
  import 'swiper/css/zoom';
  import '@ionic/vue/css/ionic-swiper.css';

  export default defineComponent({
    components: { IonContent, Swiper, SwiperSlide },
    setup() {
      return {
        modules: [Autoplay, EffectFade, Keyboard, Pagination],
        chevronForwardCircleOutline
      };
    },
    data () {
      return {
        swipVisible: true
      }
    },
    mounted(){
      setTimeout(() => {
        let animate = document.getElementById("animate")
        animate?.classList.add("start-animate")
      }, 3000)
      setTimeout(() => {
        let animate = document.getElementById("animate-after")
        animate?.classList.add("start-animate")
      }, 3500)
    },
    methods: {
      setSkipSwiper() {
        this.swipVisible = false
        this.$emit('onCloseSwiper')
      }
    }
  });
