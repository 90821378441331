import { configure, defineRule } from "vee-validate"
import { localize } from '@vee-validate/i18n'
import { setLocale } from '@vee-validate/i18n'
import it from '@vee-validate/i18n/dist/locale/it.json'
import AllRules from '@vee-validate/rules'

// language
configure({
    generateMessage: localize({
        it,
    }),
    validateOnInput: true
});
setLocale('it')

// rules
Object.keys(AllRules).forEach((rule: string) => {
    defineRule(rule, AllRules[rule])
});

export const regex= (value: string) => { 
  if(value?.length==0)return 'Il campo password è richiesto'
  if (!/[a-z]/.test(value) && !/[A-Z]/.test(value) && !/[#?!@$%^&*-]/.test(value)) return "La password deve contenere almeno 1 maiuscola, 1 minuscola e 1 simbolo"
  else if (!/[a-z]/.test(value) && /[A-Z]/.test(value) && /[#?!@$%^&*-]/.test(value)) return "La password deve contenere almeno 1 minuscola"
  else if (!/[A-Z]/.test(value) && /[a-z]/.test(value) && /[#?!@$%^&*-]/.test(value)) return "La password deve contenere almeno 1 maiuscola"
  else if (!/[#?!@$%^&*-]/.test(value) && /[A-Z]/.test(value) && /[a-z]/.test(value)) return "La password deve contenere almeno 1 simbolo (es: #?!@$%^&*-)"
  else if (/[a-z]/.test(value) && !/[A-Z]/.test(value) && !/[#?!@$%^&*-]/.test(value)) return "La password deve contenere almeno 1 maiuscola e 1 simbolo"
  else if (!/[a-z]/.test(value) && /[A-Z]/.test(value) && !/[#?!@$%^&*-]/.test(value)) return "La password deve contenere almeno 1 minuscola e 1 simbolo"
  else if (!/[a-z]/.test(value) && !/[A-Z]/.test(value) && /[#?!@$%^&*-]/.test(value)) return "La password deve contenere almeno 1 minuscola e 1 maiuscola"
  else if(value.length<8)return 'La password deve avere almeno 8 caratteri'
  else return true;
}