import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { isLogged } from '../config/user-session'
import BasicTabRoute from '@/views/BasicTabRoute.vue'
import TabsPage from '@/views/TabsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/calendar'
      },
      {
        path: 'classifiche',
        name: 'Classifiche',
        component: () => import('@/views/ClassifichePage.vue'),
      },
      {
        path: 'classifiche/:id',
        name: 'Classifica',
        component: () => import('@/views/ClassificaPage.vue'),
        props: true
      },
      {
        path: 'calendar',
        name: 'Calendario',
        component: () => import('@/views/CalendarTab.vue'),
      },
      {
        path: 'calendar/:id',
        name: 'Evento',
        component: () => import('@/views/EventPage.vue'),
        props: true
      },
      {
        path: 'calendar/:name/:id',
        name: 'Evento generico',
        component: () => import('@/views/EventPage.vue'),
        props: true
      },
      {
        path: 'calendar/map/:id',
        component: () => import('@/views/MapCalendar.vue'),
        props: true
      },
      {
        path: 'map',
        name: 'Mappa',
        component: () => import('@/views/MapTab.vue')
      },
      {
        path: 'map/:id',
        name: 'Impianto',
        component: () => import('@/views/MapTab.vue'),
        props: true
      },
      {
        path: 'map/:name/:id',
        name: 'Impianto generico',
        component: () => import('@/views/MapTab.vue'),
        props: true
      },
      {
        path: 'user',
        name: 'Pagina profilo',
        component: () => import('@/views/ProfilePage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'user/login',
        name: 'Login',
        component: () => import('@/views/LoginPage.vue')
      },
      {
        path: 'user/register',
        name: 'Registrazione',
        component: () => import('@/views/RegistrationPage.vue')
      },
      {
        path: 'user/account-confirm-email/:token',
        name: 'Conferma email',
        component: () => import('@/views/UserConfirmEmail.vue'),
        props: true
      },
      {
        path: 'user/reset/:uid/:token',
        name: 'Reset password',
        component: () => import('@/views/UserPasswordResetForm.vue'),
        props: true
      },
      {
        path: 'user/password',
        name: 'Recupero password',
        component: () => import('@/views/ForgottenPassword.vue')
      },
      {
        path: 'user/confirmIdentity',
        name: 'Conferma identità',
        component: () => import('@/views/ConfirmIdentityPage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'user/waitConfirmIdentity',
        name: 'Attendi conferma identità',
        component: () => import('@/views/WaitConfirmIdentityPage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'user/changeEmail',
        name: 'Modifica la tua email',
        component: () => import('@/views/ChangeEmail.vue')
      },
      {
        path: 'user/resendConfirmationEmail',
        name: 'Reinvio email di verifica',
        component: () => import('@/views/VerifyEmail.vue')
      },
      {
        path: 'user/calendar/:id',
        component: () => import('@/views/EventPage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'user/calendar/map/:id',
        component: () => import('@/views/MapUser.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'user/athlete',
        name: 'Profilo atleta',
        component: () => import('@/views/AthletePage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'user/communications',
        name: 'Comunicazioni',
        component: () => import('@/views/CommunicationsPage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'user/communications/:id',
        name: 'Dettaglio comunicazione',
        props: true,
        component: () => import('@/views/CommunicationsDetail.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'user/entries',
        name: 'Iscrizioni',
        component: () => import('@/views/EntriesPage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      /*
      {
        path: 'user/entry/:id',
        name: 'Iscrizione',
        component: () => import('@/views/EntryPage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'user/entry/:id/confirm',
        name: 'Conferma iscrizione',
        component: () => import('@/views/ConfirmEntry.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'user/entry/:id/remove',
        name: 'Conferma rimozione iscrizione',
        component: () => import('@/views/RemoveEntry.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      */
      {
        path: 'settings',
        name: 'Impostazioni',
        component: () => import('@/views/SettingsPage.vue'),
      },
      {
        path: 'settings/secret',
        name: 'Impostazioni private',
        component: () => import('@/views/SettingsPageSecret.vue'),
      },
      {
        path: 'settings/delete-account',
        name: 'Elimina account',
        component: () => import('@/views/SettingsDeleteAccount.vue'),
      },
      {
        path: 'settings/deactivate-account',
        name: 'Scollega account',
        component: () => import('@/views/SettingsDeactivateAccount.vue'),
      },
      {
        path: 'user/saved-events',
        name: 'Manifestazioni',
        component: () => import('@/views/FavoritesEventsPage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'user/saved-events/:id',
        name: 'Evento salvato',
        component: () => import('@/views/EventPage.vue'),
        props: true
      },
      {
        path: 'user/conta-giri',
        name: 'Lettura giri',
        component: () => import('@/views/BeaconDataPage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'user/serie-giudici',
        name: 'Giudice',
        component: () => import('@/views/LapsCounterPage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'user/serie-giudici/serie/:id',
        name: 'Serie',
        component: () => import('@/views/SeriePage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to:any, from:any) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  const logged = isLogged()

  if (to.meta.requiresAuth && !logged) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: '/user/login'
    }
  }
})

export default router
