<template>
  <ion-app :class="tabPosition == 'center' ? 'desktop-app' : 'mobile-app'">
    <ion-router-outlet></ion-router-outlet>
    <SwiperComponent v-if="swiperOpened" @onCloseSwiper="closeSwiper()" />
    <BlockVersion v-if="!swiperOpened && needUpdates" />
    <NoInternet />
  </ion-app>
</template>

<script lang="js">
import { IonApp, IonRouterOutlet, useIonRouter } from '@ionic/vue'

import { defineComponent } from 'vue'
import { isPlatform } from '@ionic/vue';
import { PushNotifications } from '@capacitor/push-notifications';
import { Plugins } from '@capacitor/core'
const { App } = Plugins

import Storage from '@/models/Storage'
import SwiperComponent from '@/components/SwiperComponent.vue'
import BlockVersion from '@/components/BlockVersion.vue'
import NoInternet from '@/components/NoInternet.vue'
import {readNotification} from '@/config/push-notification'
import { needUpdate } from '@/config/app'

import { StatusBar, Style } from '@capacitor/status-bar';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    SwiperComponent,
    BlockVersion,
    NoInternet
  },
  setup(){
    const router = useIonRouter()
    let swiperOpened = false
    if (App){
      App.addListener('appUrlOpen', (data) => {
        const slug = data.url;
        const path = slug.replace("https://web.callroom.app", "")
        location.href = path
      })
      let skipTutorial = Storage.get('skipTutorial')
      swiperOpened = !skipTutorial
    }
    const needUpdates = needUpdate()
    return {
      router,
      Storage,
      swiperOpened,
      needUpdates
    }
  },
  data () {
    return {
      tabPosition: isPlatform('desktop') ? 'top' : 'bottom'
    }
  },
  mounted() {
    if(PushNotifications && isPlatform('hybrid')){
      PushNotifications.addListener('pushNotificationActionPerformed', async (event) => {

        if(event.notification){
          const notification_data = event.notification.data
          if (notification_data){
            if(notification_data.pk){
              readNotification(notification_data.pk)
            }
            if (notification_data.url){
              this.router.replace(notification_data.url)
            }
          }
        }
      });
    }

    // setting status bar text color
    if(isPlatform('hybrid')){
      if(isPlatform('android')){
        StatusBar.setStyle({ style: Style.Dark });
      } else {
        StatusBar.setStyle({ style: Style.Light });
      }
    }
    let scope =   Storage.get('scope') || Storage.get('scopes-default')
    Storage.set('scope', scope)
  },
  methods: {
    closeSwiper() {
      Storage.set("skipTutorial", true)
    }
  }
});
</script>