import Storage from '@/models/Storage'

export const version = "1.9.0"

export const needUpdate = () => {
    const v = Storage.get('app-minVersion')
    if (v) {
        if (isVersionLess(version, v)) {
            return true
        }
    }
    return false
}

export const isMoreRecent = () => {
    const v = Storage.get('app-latestVersion')
    if (v) {
        if (isVersionLess(version, v)) {
            return false
        }
        return true
    }
    return true
}

const isVersionLess = (v1: string, v2: string) => {
    const v1_arr = v1.split(".")
    const v2_arr = v2.split(".")
    for (let i = 0; i < v1_arr.length; i++) {
        if (parseInt(v1_arr[i]) > parseInt(v2_arr[i])) {
            return false
        } else if (parseInt(v1_arr[i]) < parseInt(v2_arr[i])) {
            return true
        }
    }
    return false
}

export const defaultConfiguration = {
    "app.generic": {
        "app": {
            "latestVersion": version,
            "minVersion": version,
            "messageMinVersion": "\u00c8 necessario aggiornare l'app all'ultima versione per poter utilizzare tutte le funzionalit\u00e0",
            "linksMinversion": {
                "ios": "https://itunes.apple.com/it/app/id1625348252",
                "android": "https://play.google.com/store/apps/details?id=app.callroom.web"
            },
            "privacyLink": "https://callroom.app/privacy-policy.html",
            "mainLink": "https://callroom.app"
        },
        "calendar": {
            "max_days_after": 365,
            "max_days_before": 365,
            "max_days_interval": 60
        },
        "serieSettings": {
            "filterName": "BlueUp",
            "smooth_window": 5,
            "max_smooth_time": 5000,
            "min_power_above": 15,
            "avoid_until": {
                "400": 50000,
                "200": 20000,
                "150": 15000
            }
        },
        "scopes": {
            "default": {
                "pk": 353,
                "parent_scope": null,
                "model_name": "Area",
                "file_code": "ITA",
                "description": "Tutte le regioni"
            },
            "list": [
                {
                    "pk": 1,
                    "parent_scope": "Italia",
                    "model_name": "Area",
                    "file_code": "EMI",
                    "description": "Emilia-Romagna"
                },
                {
                    "pk": 353,
                    "parent_scope": null,
                    "model_name": "Area",
                    "file_code": "ITA",
                    "description": "Tutte le regioni"
                }
            ]
        },
        "form": {
            "login": {
                "name": "login",
                "description": "",
                "submit": {
                    "method": "POST",
                    "url": "/api/auth/token/",
                    "name": "Login"
                },
                "fields": [
                    {
                        "component": "IonInput",
                        "label": "Email",
                        "name": "email",
                        "type": "email",
                        "placeholder": "La tua email",
                        "rules": "required|email"
                    },
                    {
                        "component": "InputPassword",
                        "label": "Password",
                        "name": "password",
                        "type": "password",
                        "rules": "required",
                        "link": {
                            "name": "Password dimenticata?",
                            "url": "/user/password"
                        }
                    }
                ]
            },
            "signup": {
                "name": "signup",
                "description": "Per associarti al tuo profilo atleta ti verranno richiesti due codici identificativi forniti dalla tua societ\u00e0.",
                "submit": {
                    "method": "POST",
                    "url": "/api/auth/registration/",
                    "name": "Registrati"
                },
                "fields": [
                    {
                        "component": "IonInput",
                        "class": "",
                        "label": "Email",
                        "name": "email",
                        "type": "email",
                        "placeholder": "La tua email",
                        "rules": "required|email"
                    },
                    {
                        "component": "IonInput",
                        "label": "Nome",
                        "name": "first_name",
                        "type": "text",
                        "placeholder": "Nome",
                        "rules": "required"
                    },
                    {
                        "component": "IonInput",
                        "label": "Cognome",
                        "name": "last_name",
                        "type": "text",
                        "placeholder": "Cognome",
                        "rules": "required"
                    },
                    {
                        "component": "DatePickerComponent",
                        "name": "birth_date",
                        "label": "Data di nascita",
                        "rules": "required",
                        "type": "text",
                        "placeholder": "Data di nascita",
                        "position": "undefined",
                        "settings": {
                            "min_age": 13,
                            "date_format": "YYYY-MM-DD"
                        }
                    },
                    {
                        "component": "InputPassword",
                        "label": "Password",
                        "name": "password1",
                        "type": "password",
                        "rules": "regex",
                        "popover": {
                            "id": "signup-1",
                            "name": "Almeno 1 minuscola, 1 maiuscola e 1 simbolo",
                            "description": "Per garantire una maggiore sicurezza la tua password deve essere composta da minimo 8 caratteri, contenenti: almeno 1 lettera minuscola, 1 lettera maiuscola e 1 simbolo (esempio: @?!+-)"
                        }
                    },
                    {
                        "component": "InputPassword",
                        "label": "Ripeti password",
                        "name": "password2",
                        "type": "password",
                        "rules": "required|confirmed:@password1"
                    },
                    {
                        "component": "IonCheckbox",
                        "value": "Accetta la privacy policy",
                        "label": "Accetta la privacy policy",
                        "name": "privacy1",
                        "rules": "required",
                        "slot": "start",
                        "position": "undefined",
                        "link": {
                            "name": "Leggi privacy policy",
                            "url": "https://callroom.app/privacy-policy.html"
                        }
                    }
                ]
            },
            "forgottenPassword": {
                "name": "forgottenPassword",
                "submit": {
                    "method": "POST",
                    "url": "/api/auth/password/reset/",
                    "name": "Resetta password"
                },
                "fields": [
                    {
                        "component": "IonInput",
                        "class": "",
                        "label": "Email",
                        "name": "email",
                        "type": "email",
                        "rules": "required|email",
                        "placeholder": "La tua email"
                    }
                ]
            },
            "resendVerificationEmail": {
                "name": "resendVerificationEmail",
                "submit": {
                    "method": "POST",
                    "url": "/api/auth/registration/resend-email/",
                    "name": "Verifica la tua email"
                },
                "fields": [
                    {
                        "component": "IonInput",
                        "class": "",
                        "label": "Email",
                        "name": "email",
                        "type": "email",
                        "rules": "required|email",
                        "placeholder": "La tua email"
                    }
                ]
            },
            "changeEmail": {
                "name": "changeEmail",
                "submit": {
                    "method": "PUT",
                    "url": "/api/app/users/change_email/",
                    "name": "Cambia la tua email"
                },
                "fields": [
                    {
                        "component": "IonInput",
                        "class": "",
                        "label": "Email",
                        "name": "email",
                        "type": "email",
                        "rules": "required|email",
                        "placeholder": "Nuovo indirizzo email"
                    }
                ]
            },
            "verifyEmail": {
                "name": "verifyEmail",
                "submit": {
                    "method": "POST",
                    "url": "/api/auth/registration/verify-email/",
                    "name": "Conferma"
                },
                "fields": [
                    {
                        "component": "IonInput",
                        "name": "key",
                        "type": "hidden",
                        "class": "ion-hide",
                        "rules": "required"
                    }
                ]
            },
            "resetPassword": {
                "name": "resetPassword",
                "submit": {
                    "method": "POST",
                    "url": "/api/auth/password/reset/confirm/",
                    "name": "Cambia password"
                },
                "fields": [
                    {
                        "component": "IonInput",
                        "name": "uid",
                        "type": "hidden",
                        "class": "ion-hide",
                        "rules": "required"
                    },
                    {
                        "component": "IonInput",
                        "name": "token",
                        "type": "hidden",
                        "class": "ion-hide",
                        "rules": "required"
                    },
                    {
                        "component": "IonInput",
                        "name": "new_password1",
                        "type": "password",
                        "class": "",
                        "rules": "regex",
                        "label": "Nuova password",
                        "placeholder": "Inserisci la password"
                    },
                    {
                        "component": "IonInput",
                        "name": "new_password2",
                        "type": "password",
                        "class": "",
                        "rules": "required|confirmed:@new_password1",
                        "label": "Conferma nuova password",
                        "placeholder": "Ripeti la password"
                    }
                ]
            },
            "deleteAccount": {
                "name": "deleteAccount",
                "submit": {
                    "method": "PUT",
                    "url": "/api/app/users/drop_user_account/",
                    "name": "Elimina account",
                    "color": "danger"
                },
                "fields": []
            },
            "deactivateAccount": {
                "name": "deactivateAccount",
                "submit": {
                    "method": "PUT",
                    "url": "/api/app/users/athlete_deactivate/",
                    "name": "Scollega account",
                    "color": "danger"
                },
                "fields": []
            },
            "addSerie": {
                "name": "addSerie",
                "submit": {
                    "method": "PUT",
                    "url": "/api/app/users/add_judge_series/",
                    "name": "Salva",
                    "footer_button": true
                },
                "fields": [
                    {
                        "component": "IonInput",
                        "class": "white-filled",
                        "label": "Nome",
                        "position": "stacked",
                        "name": "name",
                        "type": "text",
                        "placeholder": "Nome della serie",
                        "rules": "required"
                    },
                    {
                        "component": "DatePickerComponent",
                        "class": "white-filled",
                        "name": "date",
                        "label": "Data:",
                        "type": "text",
                        "position": "fixed",
                        "lines": "none",
                        "rules": "required",
                        "settings": {
                            "presentation": "date-time",
                            "date_format": "YYYY-MM-DDTHH:mm:ss"
                        }
                    },
                    {
                        "component": "SelectComponent",
                        "class": "white-filled",
                        "label": "Tipologia pista",
                        "position": "stacked",
                        "name": "track_size",
                        "settings": {
                            "value": 400,
                            "selectOptions": [
                                {
                                    "label": "Outdoor",
                                    "value": 400
                                },
                                {
                                    "label": "Indoor 200m",
                                    "value": 200
                                },
                                {
                                    "label": "Indoor < 200m",
                                    "value": 150
                                }
                            ],
                        },
                        "placeholder": "Seleziona la tipologia della pista",
                        "lines": "none",
                        "rules": "required"
                    },
                    {
                        "component": "IonInput",
                        "class": "white-filled",
                        "label": "Numero di giri totali",
                        "position": "stacked",
                        "name": "total_laps",
                        "type": "number",
                        "placeholder": "0",
                        "lines": "none",
                        "rules": "required"
                    },
                    {
                        "component": "BibBeaconPairing",
                        "class": "white-filled no-ripple",
                        "label": "",
                        "name": "athletes",
                        "lines": "none",
                        "rules": "required"
                    },
                    {
                        "component": "RangePickerComponent",
                        "name": "alert_laps",
                        "settings": {
                            "min": 0,
                            "max": 2
                        },
                        "lines": "none",
                        "rules": "required"
                    }
                ]
            },
            "editSerie": {
                "name": "addSerie",
                "submit": {
                    "method": "PUT",
                    "url": "/api/app/users/edit_judge_series/",
                    "name": "Salva",
                    "footer_button": true
                },
                "fields": [
                    {
                        "component": "IonInput",
                        "class": "white-filled",
                        "label": "Nome",
                        "position": "stacked",
                        "name": "name",
                        "type": "text",
                        "placeholder": "Nome della serie",
                        "rules": "required",
                        "id": "name-input"
                    },
                    {
                        "component": "DatePickerComponent",
                        "class": "white-filled",
                        "name": "date",
                        "label": "Data:",
                        "type": "text",
                        "position": "fixed",
                        "lines": "none",
                        "rules": "required",
                        "settings": {
                            "presentation": "date-time",
                            "date_format": "YYYY-MM-DDTHH:mm:ss"
                        }
                    },
                    {
                        "component": "IonInput",
                        "class": "white-filled",
                        "label": "Numero di giri totali",
                        "position": "stacked",
                        "name": "total_laps",
                        "type": "number",
                        "placeholder": "0",
                        "lines": "none",
                        "rules": "required",
                        "id": "total_laps-input"
                    },
                    {
                        "component": "BibBeaconPairing",
                        "class": "white-filled no-ripple",
                        "label": "",
                        "name": "athletes",
                        "lines": "none",
                        "rules": "required"
                    },
                    {
                        "component": "RangePickerComponent",
                        "name": "alert_laps",
                        "settings": {
                            "min": 1,
                            "max": 50
                        },
                        "lines": "none",
                        "rules": "required"
                    }
                ]
            },
            "stopSerie": {
                "name": "stopSerie",
                "fields": []
            },
            "resetSerie": {
                "name": "resetSerie",
                "fields": [],
                "submit": {
                    "method": "PUT",
                    "url": "/api/app/users/ID/reset_judge_series/",
                    "color": "light",
                    "name": "RESET"
                }
            },
            "closeSerie": {
                "name": "closeSerie",
                "fields": [],
                "submit": {
                    "method": "PUT",
                    "url": "/api/app/users/ID/close_judge_series/",
                    "color": "danger",
                    "name": "CONCLUDI"
                }
            },
            "deleteSerie": {
                "name": "deleteSerie",
                "fields": [],
                "submit": {
                    "method": "PUT",
                    "url": "/api/app/users/ID/delete_judge_series/",
                    "name": "Elimina serie",
                    "fab": true,
                    "iconSize": "trash",
                    "iconColor": "light",
                    "size": "small",
                    "color": "danger"
                }
            },
            "settingsSerie": {
                "name": "settingsSerie",
                "submit": {
                    "name": "Salva",
                    "method": "PUT",
                    "url": "/api/app/users/ID/judge_series_settings/"
                },
                "fields": [
                    {
                        "component": "IonInput",
                        "class": "",
                        "label": "Smooth window",
                        "name": "smooth_window",
                        "type": "number",
                        "placeholder": "5"
                    },
                    {
                        "component": "IonInput",
                        "class": "",
                        "label": "Max smooth time",
                        "name": "max_smooth_time",
                        "type": "number",
                        "placeholder": "5000"
                    },
                    {
                        "component": "IonInput",
                        "class": "",
                        "label": "Min power above",
                        "name": "min_power_above",
                        "type": "number",
                        "placeholder": "15"
                    }, {
                        "component": "IonInput",
                        "class": "",
                        "label": "Avoid until",
                        "name": "avoid_until",
                        "type": "number",
                        "placeholder": "40000"
                    },
                ]
            },
            "identitycheck": {
                "name": "identitycheck",
                "submit": {
                    "method": "PUT",
                    "url": "/api/app/users/athlete_activate/",
                    "name": "Conferma identit\u00e0"
                },
                "fields": [
                    {
                        "component": "IonInput",
                        "class": "",
                        "label": "Tessera FIDAL atleta",
                        "name": "code",
                        "type": "text",
                        "placeholder": "La tua tessera FIDAL",
                        "rules": "required"
                    },
                    {
                        "component": "IonInput",
                        "label": "Codice di attivazione",
                        "name": "activation_code",
                        "type": "text",
                        "placeholder": "Il codice fornito dalla tua societ\u00e0",
                        "popover": {
                            "name": "Non hai un codice?",
                            "description": "Chiedi alla tua societ\u00e0 di fornirti il codice per accedere al servizio. L'associazione \u00e8 consentita solo per gli atleti tesserati per societ\u00e0 dell'Emilia-Romagna e per le societ\u00e0 che hanno aderito.",
                            "id": 1
                        },
                        "rules": "required"
                    }
                ]
            },
            "removeEntry": {
                "name": "removeEntry",
                "submit": {
                    "method": "PUT",
                    "url": "/api/app/users/competition_entries/:ID/change/",
                    "name": "Non confermare la partecipazione",
                    "color": "danger"
                },
                "fields": [
                    {
                        "component": "IonInput",
                        "name": "status",
                        "type": "hidden",
                        "value": 2,
                        "class": "ion-hide"
                    },
                    {
                        "component": "IonInput",
                        "label": "Inserisci la tua password",
                        "name": "password",
                        "type": "password",
                        "placeholder": "",
                        "rules": "required"
                    }
                ]
            },
            "confirmEntry": {
                "name": "confirmEntry",
                "submit": {
                    "method": "PUT",
                    "url": "/api/app/users/competition_entries/:ID/change/",
                    "name": "Conferma partecipazione"
                },
                "fields": [
                    {
                        "component": "IonInput",
                        "name": "status",
                        "type": "hidden",
                        "value": 1,
                        "class": "ion-hide"
                    },
                    {
                        "component": "IonInput",
                        "label": "Inserisci la tua password",
                        "name": "password",
                        "type": "password",
                        "placeholder": "",
                        "rules": "required"
                    }
                ]
            }
        },
        "schema": {
            "calendar": {
                "levels": {
                    "search_field": "competition|competition_level|pk",
                    "name": "Filtri livello",
                    "operator_inside": "and",
                    "depends_on": null,
                    "show_all": true,
                    "filters": {}
                },
                "types": {
                    "search_field": "competition|competition_type|pk",
                    "name": "Filtri tipo",
                    "operator_inside": "and",
                    "depends_on": null,
                    "show_all": true,
                    "filters": {}
                },
                "filter_values_calendar_events": {
                    "search_field": "filter_values_calendar_event|pk",
                    "name": "Filtri evento",
                    "operator_inside": "and",
                    "depends_on": null,
                    "show_all": true,
                    "filters": {}
                },
                "sport_events": {
                    "search_field": "calendar_event_units|competition_unit|sport_event|pk",
                    "name": "Filtri gara",
                    "operator_inside": "or",
                    "depends_on": null,
                    "searchable": true,
                    "filters": {}
                },
                "time_periods": {
                    "search_field": "calendar_event_units|date_start|time_period|pk",
                    "name": "Filtri orari gara",
                    "operator_inside": "or",
                    "depends_on": "sport_events",
                    "show_all": true,
                    "filters": {}
                },
                "categories": {
                    "search_field": "calendar_event_units|competition_unit|athlete_categories|pk",
                    "name": "Filtri categoria",
                    "operator_inside": "or",
                    "depends_on": "sport_events",
                    "searchable": true,
                    "filters": {}
                },
                "genders": {
                    "search_field": "calendar_event_units|competition_unit|gender|pk",
                    "name": "Filtri di genere",
                    "operator_inside": "or",
                    "depends_on": "sport_events",
                    "show_all": true,
                    "filters": {}
                },
                "states": {
                    "search_field": "city|state|pk",
                    "name": "Filtri per provincia",
                    "depends_on": null,
                    "operator_inside": "or",
                    "searchable": true,
                    "filters": {}
                },
                "filter_values_venues": {
                    "search_field": "competition|venue|filter_values_venue|pk",
                    "name": "Filtri impianto",
                    "operator_inside": "and",
                    "depends_on": null,
                    "show_all": true,
                    "filters": {}
                }
            },
            "venue": {
                "filter_values_venues": {
                    "search_field": "filter_values_venue|pk",
                    "name": "Specifiche impianto",
                    "operator_inside": "and",
                    "depends_on": null,
                    "show_all": true,
                    "filters": {}
                },
                "days_of_week": {
                    "search_field": "my_venue_time_table|day_of_week",
                    "name": "Giorno di apertura",
                    "operator_inside": "or",
                    "depends_on": null,
                    "show_all": true,
                    "filters": {}
                },
                "venue_types": {
                    "search_field": "type",
                    "name": "Tipo impianto",
                    "operator_inside": "or",
                    "depends_on": null,
                    "show_all": true,
                    "filters": {}
                }
            }
        }
    },
    "app.ios": {},
    "app.android": {},
    "app.web": {}
}