import Storage from '@/models/Storage'
import axios from 'axios'

import { apiProtocol, apiHost, formSchemaEndpoint } from './endpoints'
import { defaultConfiguration } from './app'
import moment from 'moment'

// form schema endpointp
export const formSchemaUrl = `${apiProtocol}://${apiHost}${formSchemaEndpoint}`

function setFirstLocalStorageData() {
    // bind response to data
    const hasStorage = Storage.get("app-minVersion") ? true : false
    if (!hasStorage) {
        const res_data = {
            'value': JSON.stringify(defaultConfiguration)
        }
        setLocalStorageData(res_data)
    }
}

// localstorage
function setLocalStorageData(data: any) {
    // bind response to data
    const j = JSON.parse(data?.value)

    const settings = j?.['app.generic']
    for(const pk in settings) {
        const f = settings[pk]
        for(const key in f){
            Storage.set(pk+'-'+key, f[key])
        }
    }

    Storage.set('settings-lastupdate', new Date().toISOString())
    Storage.clearOldResponses()
}

export const localStorage = new Storage()
export const configureLocalStorage = (callback: any) => {
    const timeout_time = Storage.get("app-minVersion") ? 5000 : 10000
    axios.get(
        formSchemaUrl.toString(),
        {
            timeout: timeout_time
        }
    )
    .then(
        res => {
            if (apiHost.indexOf("localhost") === 0 || apiHost.indexOf("192.") === 0){
                // se l'endpoint è localhost usiamo la variabile definita in app.ts
                const res_data = {
                    'value': JSON.stringify(defaultConfiguration)
                }
                console.log("[WARNING]: Override settings with local variable")
                setLocalStorageData(res_data)
            } else {
                setLocalStorageData(res.data)
            }
        }
    )
    .finally(
        () => {
            setFirstLocalStorageData()
            callback()
        }
    )
}
