import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import VueGTag from 'vue-gtag';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import 'tailwindcss/base.css';
// tailwind base is messing up with ionic typography so reset typography
import '@ionic/vue/css/typography.css';

import 'tailwindcss/components.css';
import 'tailwindcss/utilities.css';

import './theme/common.css';
import './config/vee-validate'
import { axiosInstance } from './config/access-token'
import { login, logout, isLogged } from './config/user-session'
import { configureLocalStorage } from '@/config/local-storage'

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(VueGTag, {
    config: {
      id: 'G-RZSYJ7LMBD',
      params: {
        anonymize_ip: true
      }
    },
  }, router)

app.provide('axios', axiosInstance)
app.provide('login', login)
app.provide('logout', logout)
app.provide('isLogged', isLogged)

router.isReady().then(() => {
  configureLocalStorage(() => {
    app.mount('#app');
  })
});