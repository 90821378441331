import { isLoggedIn, setAuthTokens, clearAuthTokens, getAccessToken, getRefreshToken } from 'axios-jwt'
import { axiosInstance } from './access-token'
import { apiTokenPath } from './endpoints'
import Storage from '@/models/Storage'

// 4. Post email and password and get tokens in return. Call setAuthTokens with the result.
export const setLoginData = (data: any) => {
    setAuthTokens({
        accessToken: data.access,
        refreshToken: data.refresh
    })

    // save user info
    setUser(data.user)
}

export const setUser = (user: any) => {
    delete user.judge_series
    Storage.set("user", user)
}

export const login = async (params: any) => {
    const response = await axiosInstance.post(`${apiTokenPath}`, params)
    // save tokens to storage
    setLoginData(response.data)
}

// 5. Clear the auth tokens from localstorage
export const logout = () => {
    clearAuthTokens()
    Storage.clearUserInfo()
}
export const isLogged = () => isLoggedIn()

// vars
export const accessToken = getAccessToken()
export const refreshToken = getRefreshToken()